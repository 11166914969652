<template lang="pug">
.delete-account
  .header
    h1.title Delete Account
    h3.description
      | Deleting your account will remove all of your information
      | from our database. This cannot be undone.
  .body
    .form-item
      span.label Account Name:
      .input
        input(type="text", v-model="accountName")
    button(type="submit", @click="submit") Submit Ticket
</template>

<script>
export default {
  data() {
    return {
      accountName: '',
    };
  },
  methods: {
    submit() {
      if (!this.accountName) alert('Please enter your account name.');
      else {
        alert('Submit successfully. ');
        this.$router.replace('/English');
      }
    },
  },
};
</script>

<style lang="stylus">
.delete-account
  width 100%
  height 100vh
  background radial-gradient(175.03% 104.15% at 88.52% 8.8%, #FFFCEF 0%, #E0F5F4 100%)
  font-family "PingFang SC"
  font-style normal
  display flex
  flex-direction column
  justify-content center
  gap 80px
  .header
    width 1044px
    margin 0 auto
    font-weight 600
    color #061C32
    h1.title
      font-size 64px
      line-height 74px
    h3.description
      font-size 32px
      line-height 56px
      margin-top 22px
  .body
    width 756px
    margin 0 auto
    .form-item
      display flex
      align-items center
      gap 24px
      position relative
      .label
        color #061C32
        font-size 32px
        font-weight 600
        line-height 56px
      .input
        position relative
        &::after
          content "*Please enter your account name."
          position absolute
          left 0
          bottom -8px
          transform translateY(100%)
          color #F23232
          font-size 18px
        input
          height 56px;
          flex-shrink 0;
          flex-grow 1;
          border-radius 16px;
          border 1px solid #0A5266;
          background-color transparent
          line-height 100%
          padding 0 16px
          font-size 32px
    button
      border none
      cursor pointer
      width 100%
      height 66px
      margin-top 120px
      border-radius 10.5px
      background-color #FF592B
      color #FFF
      font-size 24px
      font-weight 600
      &:hover
        background-color #FF7340
      &:active
        background-color #D93209
</style>
